// LOADER STYLES
.loaderContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  background: #002642;
  z-index: 1000;
}

.loaderIcon {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderIcon::before,
.loaderIcon::after {
  content: '';
  position: absolute;
  width: 65px;
  height: 65px;
  background-color: #f5e9e2;
  border-radius: 50%;
  animation: loader 1.2s ease-in-out infinite;
  box-sizing: border-box;

  @media (max-width: 3839px) {
    width: 33px;
    height: 33px;
  }

  @media (max-width: 1919px) {
    width: 26px;
    height: 26px;
  }

  @media (max-width: 767px) {
    width: 20px;
    height: 20px;
  }
}

.loaderIcon::before {
  left: calc(50% - 65px - 65px);

  @media (max-width: 3839px) {
    left: calc(50% - 33px - 33px);
  }

  @media (max-width: 1919px) {
    left: calc(50% - 26px - 26px);
  }

  @media (max-width: 767px) {
    left: calc(50% - 20px - 20px);
  }
}

.loaderIcon::after {
  left: calc(50% + 65px);
  animation-delay: -0.4s;

  @media (max-width: 3839px) {
    left: calc(50% + 33px);
  }

  @media (max-width: 1919px) {
    left: calc(50% + 26px);
  }

  @media (max-width: 767px) {
    left: calc(50% + 20px);
  }
}

@keyframes loader {
  0%,
  100% {
    transform: translateY(-100px);
  }

  44% {
    transform: translateY(100px);
  }
}

@media (max-width: 3839px) {
  @keyframes loader {
    0%,
    100% {
      transform: translateY(-55px);
    }

    44% {
      transform: translateY(55px);
    }
  }
}

@media (max-width: 1919px) {
  @keyframes loader {
    0%,
    100% {
      transform: translateY(-45px);
    }

    44% {
      transform: translateY(45px);
    }
  }
}

@media (max-width: 767px) {
  @keyframes loader {
    0%,
    100% {
      transform: translateY(-38px);
    }

    44% {
      transform: translateY(38px);
    }
  }
}

// CONTACTS SECTION
.contacts {
  position: relative;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.contactsVideo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.contactsBackgroundColor {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.contactsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 140px 74px 58px 74px;

  @media (max-width: 3839px) {
    padding: 100px 71px 32px 71px;
  }
  @media (max-width: 2559px) {
    padding: 100px 50px 20px 50px;
  }
  @media (max-width: 1919px) {
    padding: 48px 37px 20px 37px;
  }
  @media (max-width: 767px) {
    padding: 32px 14px 16px 14px;
  }
}

.contactsDescription {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  @media (max-width: 1365px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  @media (max-width: 767px) {
    gap: 8px;
  }

  h2 {
    font: var(--titleSecond);
    color: #f5e9e2;
  }

  p {
    width: 1883px;
    font: var(--textSecond);
    color: #f5e9e2;

    @media (max-width: 3839px) {
      width: 1279px;
    }
    @media (max-width: 2559px) {
      width: 960px;
    }
    @media (max-width: 1919px) {
      width: 640px;
    }
    @media (max-width: 1365px) {
    }
    @media (max-width: 767px) {
      width: 100%;
      font: var(----textThird);
    }
  }
}

.contactsInfo {
  z-index: 5;

  .contactsInfoTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 58px 0;
    border-top: 1px solid rgba(245, 233, 226, 0.3);

    @media (max-width: 3839px) {
      padding: 40px 0;
    }
    @media (max-width: 2559px) {
      padding: 20px 0;
    }
    @media (max-width: 1919px) {
    }
    @media (max-width: 1365px) {
      align-items: start;
      flex-direction: column;
      gap: 24px;
    }
    @media (max-width: 767px) {
      min-height: 166px;
      max-height: 166px;
      justify-content: flex-start;
    }

    .contactsInfoTopBtns {
      display: flex;
      justify-content: center;
      gap: 40px;

      @media (max-width: 3839px) {
        gap: 32px;
      }
      @media (max-width: 2559px) {
        gap: 16px;
      }
      @media (max-width: 1919px) {
      }
      @media (max-width: 767px) {
        width: 100%;
        overflow-x: scroll;
        gap: 8px;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      button {
        font: var(--titleFourth);
        color: #f5e9e2;
        border: 1px solid #f5e9e2;
        padding: 20px 28px;
        border-radius: 100px;
        opacity: 0.5;

        @media (max-width: 3839px) {
          padding: 14px 18px;
        }
        @media (max-width: 2559px) {
          padding: 8px 14px;
        }
        @media (max-width: 1919px) {
        }
        @media (max-width: 767px) {
          // padding: 8px 14px;
          white-space: nowrap;
        }
      }
    }

    address {
      font: var(--titleFourth);
      color: #f5e9e2;
      text-align: center;
    }
  }

  .contactsInfoMiddle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 58px 0;

    border-top: 1px solid rgba(245, 233, 226, 0.3);

    @media (max-width: 3839px) {
      padding: 28px 0;
    }
    @media (max-width: 2559px) {
      padding: 20px 0;
    }
    @media (max-width: 1919px) {
    }

    .contactsInfoMiddleLinks {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font: var(--titleFourth);
      color: #f5e9e2;

      @media (max-width: 3839px) {
      }
      @media (max-width: 2559px) {
      }
      @media (max-width: 1919px) {
      }
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: start;
        gap: 16px;
      }

      .contactsInfoMiddleLinksRight {
        display: flex;
        gap: 40px;

        a {
          &:nth-child(2) {
            margin-right: 160px;

            @media (max-width: 3839px) {
              margin-right: 100px;
            }
            @media (max-width: 2559px) {
              margin-right: 50px;
            }
            @media (max-width: 1919px) {
              margin-right: 40px;
            }
            @media (max-width: 1365px) {
              margin-right: 0px;
            }
          }
        }

        @media (max-width: 3839px) {
          gap: 32px;
        }
        @media (max-width: 2559px) {
          gap: 24px;
        }
        @media (max-width: 1919px) {
          gap: 20px;
        }
        @media (max-width: 1365px) {
          width: 55%;
          height: 52px;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 8px;
          @media (max-width: 767px) {
            width: 100%;
          }

          a {
            width: 150px;
          }
        }
        @media (max-width: 767px) {
          align-content: space-between;
        }
      }
    }
  }

  .contactsInfoBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 58px;
    border-top: 1px solid rgba(245, 233, 226, 0.3);

    @media (max-width: 3839px) {
      padding-top: 32px;
    }
    @media (max-width: 2559px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    @media (max-width: 767px) {
      position: relative;
      flex-direction: column;
    }

    li {
      font: var(--titleFifth);
      color: #f5e9e2;
      z-index: 1;
      width: 30%;

      @media (max-width: 767px) {
        width: max-content;
      }

      &:nth-child(1) {
        text-align: left;
      }
      &:nth-child(2) {
        text-align: center;
      }
      &:nth-child(3) {
        text-align: right;
      }

      a {
        width: max-content;
      }
    }

    li {
      &:nth-child(1) {
        @media (max-width: 767px) {
          position: absolute;
          left: 0;
          top: 27px;
        }
      }
      &:nth-child(2) {
        @media (max-width: 767px) {
          align-self: flex-end;
        }
      }
      &:nth-child(3) {
        @media (max-width: 767px) {
          align-self: flex-end;
        }
      }
    }
  }
}

//FLEET SECTION
.fleet {
  padding: 140px 75px 175px 75px;

  @media (max-width: 3839px) {
    padding: 100px 71px 175px 71px;
  }
  @media (max-width: 2559px) {
    padding: 100px 50px 150px 50px;
  }
  @media (max-width: 1919px) {
    padding: 80px 38px 140px 38px;
  }
  @media (max-width: 1365px) {
    padding: 80px 38px 122px 38px;
  }
  @media (max-width: 767px) {
    padding: 40px 17px 35px 17px;
  }
}

.fleetContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    margin-bottom: 140px;
    font: var(--textFirst);
    color: #f5e9e2;

    @media (max-width: 3839px) {
      margin-bottom: 100px;
    }
    @media (max-width: 1919px) {
      margin-bottom: 80px;
    }
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }

    span {
      font-style: italic;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 2040px;
    margin-bottom: 130px;

    @media (max-width: 3839px) {
      width: 1388px;
      margin-bottom: 50px;
    }
    @media (max-width: 2559px) {
      width: 1200px;
    }
    @media (max-width: 1919px) {
      width: 800px;
      margin-bottom: 30px;
    }
    @media (max-width: 1365px) {
      width: 580px;
      margin-bottom: 40px;
    }
    @media (max-width: 767px) {
      width: 340px;
      margin-bottom: 50px;
    }

    &:nth-child(odd) {
      align-self: flex-end;
    }

    &:nth-child(even) {
      align-self: flex-start;
    }

    img {
      width: 100%;
      object-fit: contain;
      margin-bottom: 60px;

      @media (max-width: 3839px) {
        margin-bottom: 40px;
      }
      @media (max-width: 2559px) {
        margin-bottom: 30px;
      }
      @media (max-width: 1919px) {
        margin-bottom: 20px;
      }
      @media (max-width: 1365px) {
        margin-bottom: 15px;
      }
      @media (max-width: 767px) {
        margin-bottom: 12px;
      }
    }

    div {
      display: flex;
      flex-direction: row;
      @media (max-width: 767px) {
        gap: 8px;
        flex-direction: column-reverse;
        align-items: center;
      }
      p {
        font: var(--textThird);
        color: #f5e9e2;

        @media (max-width: 767px) {
          width: max-content;
        }
      }
      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        margin-right: 30px;
        text-align: center;
        font: var(--textFourth);
        color: #002642;
        background-color: #f5e9e2;
        border-radius: 50%;

        @media (max-width: 3839px) {
          width: 80px;
          height: 80px;
        }
        @media (max-width: 2559px) {
          width: 60px;
          height: 60px;
        }
        @media (max-width: 1919px) {
          margin-right: 8px;
          width: 40px;
          height: 40px;
        }
        @media (max-width: 767px) {
          width: 34px;
          height: 34px;
        }
      }
    }
  }
}

// LAYOUT STYLES

// .menu[data-collapse="true"] {
//   transform: scaleY(1);
// }

.header {
  position: fixed;
  padding: 40px 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
  transform: translateY(0%);
  transition: transform 0.3s ease-in-out;
  z-index: 4000;

  @media (max-width: 3839px) {
    padding: 40px 70px;
  }

  @media (max-width: 2559px) {
    padding: 30px 50px;
  }

  @media (max-width: 1919px) {
    padding: 24px 38px;
  }

  @media (max-width: 1365px) {
    padding: 30px 37px;
  }

  @media (max-width: 767px) {
    padding: 16px 18px;
  }
}

.logotype {
  width: 312px;
  height: 100px;
  cursor: pointer;
  pointer-events: all;
  z-index: 4001;

  @media (max-width: 3839px) {
    width: 250px;
    height: 80px;
  }

  @media (max-width: 2559px) {
    width: 155px;
    height: 50px;
  }

  @media (max-width: 1919px) {
    width: 125px;
    height: 40px;
  }

  @media (max-width: 1365px) {
    width: 137px;
    height: 44px;
  }

  @media (max-width: 767px) {
    width: 100px;
    height: 32px;
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  padding-left: 390px;
  padding-right: 390px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #002642;
  z-index: 3900;

  @media (max-width: 3839px) {
    padding-left: 277px;
    padding-right: 277px;
  }

  @media (max-width: 2559px) {
    padding-left: 204px;
    padding-right: 204px;
  }

  @media (max-width: 1919px) {
    padding-left: 150px;
    padding-right: 150px;
  }

  @media (max-width: 1365px) {
    padding-left: 90px;
    padding-right: 90px;
  }

  @media (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    justify-content: flex-start;
    align-items: flex-end;
  }

  ul {
    display: flex;
    flex-direction: column;

    li {
      &:not(:last-child) {
        margin-bottom: 100px;

        @media (max-width: 3839px) {
          margin-bottom: 50px;
        }

        @media (max-width: 1919px) {
          margin-bottom: 36px;
        }

        @media (max-width: 1365px) {
          margin-bottom: 40px;
        }

        @media (max-width: 767px) {
          margin-bottom: 24px;
        }
      }

      a {
        padding: 2px 0;
        font: var(--textFirst);
        color: #f5e9e2;

        @media (max-width: 767px) {
          font-family: 'Genos';
          font-style: normal;
          font-weight: 400;
          font-size: 28px;
          line-height: 26px;
        }
      }
    }
  }

  .contentContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1000px;
    height: 1120px;
    transition: transform 1s ease-in-out;

    @media (max-width: 3839px) {
      width: 625px;
      height: 700px;
    }

    @media (max-width: 2559px) {
      width: 535px;
      height: 600px;
    }

    @media (max-width: 1919px) {
      width: 400px;
      height: 450px;
    }

    @media (max-width: 1365px) {
      display: none;
    }

    video,
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      object-fit: cover;
      transition: opacity 0.5s ease-in-out;
    }
  }
}

// .menu {
//   position: fixed;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   width: 100%;
//   height: 100vh;
// }

// .menuBackground {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   z-index: 10000;
//   transition: background-color 0.5s ease-in-out;
// }

.menuBtn {
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f5e9e2;
  outline: none;
  border: none;
  cursor: pointer;
  pointer-events: all;
  z-index: 4001;

  @media (max-width: 1919px) {
    width: 34px;
    height: 34px;
  }
}

// ЧТОБЫ СДЕЛАТЬ БОЛЬШЕ -> Х2 РАЗМЕРЫ
// vars
$color: #002642; // icon color
$blue: blue;
$animation: 0.6s; // animation speed
$scale: 1; // icon scale 68/68 default

// spacing + background-color

.hamburglar {
  transform: scale($scale);
  margin: 40px auto;
  position: relative;
  display: block;
  width: 68px;
  height: 68px;
  background: transparent;
  -webkit-touch-callout: none;
  border-radius: 50%;
  user-select: none;

  @media (max-width: 1919px) {
    margin: 0;
    width: 34px;
    height: 34px;
  }
}

// transition mask
.pathBurger {
  position: absolute;
  top: 0;
  left: 0;
  height: 68px;
  width: 68px;
  // two masks because... browser support.
  mask: url(#mask);
  -webkit-mask-box-image: url(https://raygun.io/upload/mask.svg);

  @media (max-width: 1919px) {
    width: 34px;
    height: 34px;
  }
}

.animatePath {
  position: absolute;
  top: 0;
  left: 0;
  width: 68px;
  height: 68px;

  @media (max-width: 1919px) {
    width: 34px;
    height: 34px;
  }
}

// what this does is create a small square that I then rotate behind an svg mask, giving the apparence of the line animating
.pathRotation {
  height: 34px;
  width: 34px;
  margin: 34px 34px 0 0;
  transform: rotate(0deg);
  transform-origin: 100% 0;

  @media (max-width: 1919px) {
    width: 17px;
    height: 17px;
  }

  &:before {
    content: '';
    display: block;
    width: 30px;
    height: 34px;
    margin: 0 4px 0 0;
    background: $color;

    @media (max-width: 1919px) {
      width: 15px;
      height: 17px;
      margin: 0 2px 0 0;
    }
  }
}

// box rotation animation
@keyframes rotate-out {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-in {
  0% {
    transform: rotate(360deg);
  }
  40% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

// offset moves
// dasharray is the dash size
// need to be able to control dash space size.

.hamburglar.isOpen {
  background-color: #f5e9e2;
  .path {
    animation: dash-in $animation linear normal;
    animation-fill-mode: forwards;
  }
  .animatePath {
    animation: rotate-in $animation linear normal;
    animation-fill-mode: forwards;
  }
}

.hamburglar.isClosed {
  .path {
    animation: dash-out $animation linear normal;
    animation-fill-mode: forwards;
  }
  .animatePath {
    animation: rotate-out $animation linear normal;
    animation-fill-mode: forwards;
  }
}

.path {
  stroke-dasharray: 240;
  stroke-dashoffset: 240;
  stroke-linejoin: round;

  @media (max-width: 1919px) {
    stroke-dasharray: 120;
    stroke-dashoffset: 120;
  }
}

@keyframes dash-in {
  0% {
    stroke-dashoffset: 240;
  }
  40% {
    stroke-dashoffset: 240;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash-out {
  0% {
    stroke-dashoffset: 0;
  }
  40% {
    stroke-dashoffset: 240;
  }
  100% {
    stroke-dashoffset: 240;
  }
}

@media (max-width: 1919px) {
  @keyframes dash-in {
    0% {
      stroke-dashoffset: 120;
    }
    40% {
      stroke-dashoffset: 120;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes dash-out {
    0% {
      stroke-dashoffset: 0;
    }
    40% {
      stroke-dashoffset: 120;
    }
    100% {
      stroke-dashoffset: 120;
    }
  }
}

.burgerIcon {
  position: absolute;
  padding: 20px 16px;
  height: 68px;
  width: 68px;

  @media (max-width: 1919px) {
    width: 34px;
    height: 34px;
    padding: 10px 8px;
  }
}

.burgerContainer {
  position: relative;
  height: 28px;
  width: 36px;

  @media (max-width: 1919px) {
    height: 14px;
    width: 18px;
  }
}

.burgerBunTop,
.burgerBunBot,
.burgerFilling {
  position: absolute;
  display: block;
  height: 4px;
  width: 36px;
  border-radius: 2px;
  background: $color;

  @media (max-width: 1919px) {
    height: 2px;
    width: 18px;
  }
}

.burgerBunTop {
  top: 0;
  transform-origin: 34px 2px;

  @media (max-width: 1919px) {
    transform-origin: 17px 1px;
  }
}

.burgerBunBot {
  bottom: 0;
  transform-origin: 34px 2px;

  @media (max-width: 1919px) {
    transform-origin: 17px 1px;
  }
}
//.burger-filling {
//  @include transition(all,($animation/2.5),ease-in-//out);
//}
// relative parent is the button
.burgerFilling {
  top: 12px;

  @media (max-width: 1919px) {
    top: 6px;
  }
}

// burger ring container
.burgerRing {
  position: absolute;
  top: 0;
  left: 0;
  width: 68px;
  height: 68px;

  @media (max-width: 1919px) {
    width: 34px;
    height: 34px;
  }
}

.svgRing {
  width: 68px;
  height: 68px;

  @media (max-width: 1919px) {
    display: inline-block;
    display: none;
    width: 34px !important;
    height: 34px !important;
  }
}

// bun animations
.hamburglar.isOpen {
  .burgerBunTop {
    animation: bun-top-out $animation linear normal;
    animation-fill-mode: forwards;
  }
  .burgerBunBot {
    animation: bun-bot-out $animation linear normal;
    animation-fill-mode: forwards;
  }
}
.hamburglar.isClosed {
  .burgerBunTop {
    animation: bun-top-in $animation linear normal;
    animation-fill-mode: forwards;
  }
  .burgerBunBot {
    animation: bun-bot-in $animation linear normal;
    animation-fill-mode: forwards;
  }
}

@keyframes bun-top-out {
  0% {
    left: 0;
    top: 0;
    transform: rotate(0deg);
  }
  20% {
    left: 0;
    top: 0;
    transform: rotate(15deg);
  }
  80% {
    left: -5px;
    top: 0;
    transform: rotate(-60deg);
  }
  100% {
    left: -5px;
    top: 1px;
    transform: rotate(-45deg);
  }
}

@keyframes bun-bot-out {
  0% {
    left: 0;
    transform: rotate(0deg);
  }
  20% {
    left: 0;
    transform: rotate(-15deg);
  }
  80% {
    left: -5px;
    transform: rotate(60deg);
  }
  100% {
    left: -5px;
    transform: rotate(45deg);
  }
}

@keyframes bun-top-in {
  0% {
    left: -5px;
    bot: 0;
    transform: rotate(-45deg);
  }
  20% {
    left: -5px;
    bot: 0;
    transform: rotate(-60deg);
  }
  80% {
    left: 0;
    bot: 0;
    transform: rotate(15deg);
  }
  100% {
    left: 0;
    bot: 1px;
    transform: rotate(0deg);
  }
}

@keyframes bun-bot-in {
  0% {
    left: -5px;
    transform: rotate(45deg);
  }
  20% {
    left: -5px;
    bot: 0;
    transform: rotate(60deg);
  }
  80% {
    left: 0;
    bot: 0;
    transform: rotate(-15deg);
  }
  100% {
    left: 0;
    transform: rotate(0deg);
  }
}

@media (max-width: 1919px) {
  @keyframes bun-top-out {
    0% {
      left: 0;
      top: 0;
      transform: rotate(0deg);
    }
    20% {
      left: 0;
      top: 0;
      transform: rotate(15deg);
    }
    80% {
      left: -2.5px;
      top: 0;
      transform: rotate(-60deg);
    }
    100% {
      left: -2.5px;
      top: 1px;
      transform: rotate(-45deg);
    }
  }

  @keyframes bun-bot-out {
    0% {
      left: 0;
      transform: rotate(0deg);
    }
    20% {
      left: 0;
      transform: rotate(-15deg);
    }
    80% {
      left: -2.5px;
      transform: rotate(60deg);
    }
    100% {
      left: -2.5px;
      transform: rotate(45deg);
    }
  }

  @keyframes bun-top-in {
    0% {
      left: -2.5px;
      bot: 0;
      transform: rotate(-45deg);
    }
    20% {
      left: -2.5px;
      bot: 0;
      transform: rotate(-60deg);
    }
    80% {
      left: 0;
      bot: 0;
      transform: rotate(15deg);
    }
    100% {
      left: 0;
      bot: 1px;
      transform: rotate(0deg);
    }
  }

  @keyframes bun-bot-in {
    0% {
      left: -2.5px;
      transform: rotate(45deg);
    }
    20% {
      left: -2.5px;
      bot: 0;
      transform: rotate(60deg);
    }
    80% {
      left: 0;
      bot: 0;
      transform: rotate(-15deg);
    }
    100% {
      left: 0;
      transform: rotate(0deg);
    }
  }
}

// burger filling
.hamburglar.isOpen {
  .burgerFilling {
    animation: burger-fill-out $animation linear normal;
    animation-fill-mode: forwards;
  }
}

.hamburglar.isClosed {
  .burgerFilling {
    animation: burger-fill-in $animation linear normal;
    animation-fill-mode: forwards;
  }
}

@keyframes burger-fill-in {
  0% {
    width: 0;
    left: 36px;
  }
  40% {
    width: 0;
    left: 40px;
  }
  80% {
    width: 36px;
    left: -6px;
  }
  100% {
    width: 36px;
    left: 0px;
  }
}

@keyframes burger-fill-out {
  0% {
    width: 36px;
    left: 0px;
  }
  20% {
    width: 42px;
    left: -6px;
  }

  40% {
    width: 0;
    left: 40px;
  }

  100% {
    width: 0;
    left: 36px;
  }
}

@media (max-width: 1919px) {
  @keyframes burger-fill-in {
    0% {
      width: 0;
      left: 18px;
    }
    40% {
      width: 0;
      left: 20px;
    }
    80% {
      width: 18px;
      left: -3px;
    }
    100% {
      width: 18px;
      left: 0px;
    }
  }

  @keyframes burger-fill-out {
    0% {
      width: 18px;
      left: 0px;
    }
    20% {
      width: 21px;
      left: -6px;
    }

    40% {
      width: 0;
      left: 20px;
    }

    100% {
      width: 0;
      left: 18px;
    }
  }
}
